import React, { useEffect, useState } from 'react';
import {
	Dialog,
	DialogContent,
	IconButton,
	Typography,
	Grid,
	List,
	ListItem,
	ListItemText,
	Box,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	TextField,
	Button,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { toLocaleString, paymentStatusFormat } from '../../../util';
import AgentService from '../../../services/agent.service';
import moment from 'moment';
import QuotationService from '../../../services/quotation.service';
import { useSnackbar } from 'notistack';
import pdfUrl from '../../../assets/images/pdf-placeholder.png';
import imgUrl from '../../../assets/images/image-placeholder.jpg';
import authService from '../../../services/auth.service';

const DialogTitle = (props) => {
	const { children, onClose } = props;
	return (
		<Grid container direction="row" justifyContent="space-between" alignItems="center" p={3} pb={0}>
			<Typography variant="h5">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" onClick={onClose}>
					<Close />
				</IconButton>
			) : null}
		</Grid>
	);
};

const QuotationView = (props) => {
	const { onClose, open, data } = props;
	const commissionData = data?.commissionCalculation ? JSON.parse(data?.commissionCalculation) : {};
	const premiumData = data?.premiumCalculation ? JSON.parse(data?.premiumCalculation) : {};
	const pointsData = data?.pointsCalculations ? JSON.parse(data?.pointsCalculations) : {};
	let pointPercentage = (pointsData?.bangjaminPoints / premiumData?.totalPremium) * 100 * 1000;
	let commissionPercentage =
		((commissionData?.totalCommisionAmount - commissionData?.taxAmount) / commissionData?.totalPremiumForComision) *
		100;
	const insuranceProductData = data?.insuranceProduct || {};
	const [agentProfile, setAgentProfile] = useState(null);
	const [initialDamages, setInitialDamages] = useState([]);
	const [damageAssessmentResult, setDamageAssessmentResult] = useState(null);
	const { enqueueSnackbar } = useSnackbar();
	const [canEdit, setCanEdit] = useState(true);

	console.log('quotation data:', data);

	const formatInitialDamagesState = (initialDamagesData) => {
		const formattedInitialDamagesState = initialDamagesData.map((data) => {
			return {
				...data,
				isEditMode: false,
				isNew: false,
				lastSavedState: data.content,
				isSubmitting: false,
			};
		});

		return formattedInitialDamagesState;
	};

	useEffect(() => {
		if (data?.userId) {
			AgentService.agentProfile(data?.userId)
				.then((response) => {
					setAgentProfile(response.data.data);
					console.log(response.data.data);
				})
				.catch((err) => {
					setAgentProfile({ name: '-', multilineScheme: '-' });
					// setLoading(false)
					console.log('error', err);
				});
		}

		QuotationService.getInitialDamagesByQuotationId(data.id)
			.then((response) => {
				const tmp = response.data.data.initialDamages;
				const formattedState = formatInitialDamagesState(tmp);
				setInitialDamages(tmp && tmp.length ? formattedState : []);
			})
			.catch((err) => {
				console.log('error', err);
			});

		QuotationService.getDamageAssessmentResultByQuotationId(data.id)
			.then((response) => {
				setDamageAssessmentResult(response.data.data);
			})
			.catch((err) => {
				console.log('error', err);
			});
		const user = authService.getCurrentUser();
		if (user?.permissions && user?.permissions === 'read-only') {
			setCanEdit(false);
		}
	}, []);

	const listingFunc = (title, value) => {
		return value ? (
			<ListItem sx={{ width: '25%' }}>
				<ListItemText primary={title} secondary={value || ''} />
			</ListItem>
		) : (
			''
		);
	};

	const listingFuncUrl = (title, url) => {
		return url ? (
			<ListItem sx={{ width: '25%' }}>
				{' '}
				<ListItemText
					primary={title}
					secondary={
						<a target="_blank" href={url}>
							Download
						</a>
					}
				/>{' '}
			</ListItem>
		) : (
			''
		);
	};

	const handleAddInitialDamages = () => {
		setInitialDamages(
			initialDamages.concat({
				content: '',
				isEditMode: true,
				isNew: true,
				lastSavedState: '',
				isSubmitting: false,
			})
		);
	};

	const handleCancelInitialDamages = (index, initialDamage) => {
		let newInitialDamages = {};
		if (initialDamage.isNew) {
			newInitialDamages = initialDamages.filter((_, idx) => idx != index);
		} else {
			newInitialDamages = initialDamages.map((initialDamageData, idx) => {
				if (initialDamageData.id == initialDamage.id) {
					const tmp = initialDamageData;
					Object.assign(tmp, { content: tmp.lastSavedState, isEditMode: false });
					return tmp;
				}
				return initialDamageData;
			});
		}
		setInitialDamages(newInitialDamages);
	};

	const handleChangeInitialDamagesInput = (index, event) => {
		event.preventDefault();
		const newInitialDamages = initialDamages.map((initialDamage, idx) => {
			if (idx == index) {
				const tmp = initialDamage;
				tmp.content = event.target.value;
				return tmp;
			}
			return initialDamage;
		});
		setInitialDamages(newInitialDamages);
	};

	const handleSaveInitialDamages = (index, initialDamage) => {
		if (initialDamage.isNew) {
			const newInitialDamages = initialDamages.map((data, idx) => {
				if (idx == index) {
					const tmp = data;
					Object.assign(tmp, { isSubmitting: true });
					return tmp;
				}
				return data;
			});
			setInitialDamages(newInitialDamages);
			QuotationService.addInitialDamage(data.id, initialDamage.content)
				.then((response) => {
					const newInitialDamages = initialDamages.map((data, idx) => {
						if (idx == index) {
							return {
								id: response?.data?.data.newlyAddedInitialDamage.id,
								content: response?.data?.data.newlyAddedInitialDamage.content,
								isEditMode: false,
								isNew: false,
								lastSavedState: response?.data?.data.newlyAddedInitialDamage.content,
								isSubmitting: false,
							};
						}
						return data;
					});
					setInitialDamages(newInitialDamages);
				})
				.catch((err) => {
					const newInitialDamages = initialDamages.map((data, idx) => {
						if (idx == index) {
							const tmp = data;
							Object.assign(tmp, { isSubmitting: false });
							return tmp;
						}
						return data;
					});
					if (err?.response?.data) {
						enqueueSnackbar(err.response.data.message, { variant: 'error' });
					} else {
						enqueueSnackbar(err.message, { variant: 'error' });
					}
					setInitialDamages(newInitialDamages);
				});
		} else {
			const newInitialDamages = initialDamages.map((data, idx) => {
				if (idx == index) {
					const tmp = data;
					Object.assign(tmp, { isSubmitting: true });
					return tmp;
				}
				return data;
			});
			setInitialDamages(newInitialDamages);
			QuotationService.updateInitialDamage(data.id, initialDamage.id, initialDamage.content)
				.then(() => {
					const newInitialDamages = initialDamages.map((data, idx) => {
						if (idx == index) {
							const tmp = initialDamage;
							Object.assign(tmp, {
								lastSavedState: tmp.content,
								isEditMode: false,
								isNew: false,
								isSubmitting: false,
							});
							return tmp;
						}
						return data;
					});
					setInitialDamages(newInitialDamages);
				})
				.catch((err) => {
					const newInitialDamages = initialDamages.map((data, idx) => {
						if (idx == index) {
							const tmp = data;
							Object.assign(tmp, { isSubmitting: false });
							return tmp;
						}
						return data;
					});
					if (err?.response?.data) {
						enqueueSnackbar(err.response.data.message, { variant: 'error' });
					} else {
						enqueueSnackbar(err.message, { variant: 'error' });
					}
					setInitialDamages(newInitialDamages);
				});
		}
	};

	const handleEditInitialDamages = (initialDamage) => {
		const newInitialDamages = initialDamages.map((initialDamageData) => {
			if (initialDamageData.id == initialDamage.id) {
				const tmp = initialDamageData;
				Object.assign(tmp, { isEditMode: true });
				return tmp;
			}
			return initialDamageData;
		});
		setInitialDamages(newInitialDamages);
	};

	const handleDeleteInitialDamages = (index, initialDamage) => {
		const newInitialDamages = initialDamages.map((data, idx) => {
			if (idx == index) {
				const tmp = data;
				Object.assign(tmp, { isSubmitting: true });
				return tmp;
			}
			return data;
		});
		setInitialDamages(newInitialDamages);
		QuotationService.deleteInitialDamage(data.id, initialDamage.id)
			.then(() => {
				const initialDamagesFiltered = initialDamages.filter((_, idx) => idx != index);
				const newInitialDamages = initialDamagesFiltered.map((data, idx) => {
					if (idx == index) {
						const tmp = data;
						Object.assign(tmp, { isSubmitting: false });
						return tmp;
					}
					return data;
				});
				setInitialDamages(newInitialDamages);
			})
			.catch((err) => {
				const newInitialDamages = initialDamages.map((data, idx) => {
					if (idx == index) {
						const tmp = data;
						Object.assign(tmp, { isSubmitting: false });
						return tmp;
					}
					return data;
				});
				if (err?.response?.data) {
					enqueueSnackbar(err.response.data.message, { variant: 'error' });
				} else {
					enqueueSnackbar(err.message, { variant: 'error' });
				}
				setInitialDamages(newInitialDamages);
			});
	};

	const imageErrorHandle = (event) => {
		let img = event.target.src;
		if (img.includes('.pdf')) {
			event.target.src = pdfUrl;
		} else if (img.includes('.png') || img.includes('.jpeg') || img.includes('.jpg')) {
			event.target.src = imgUrl;
		} else if (img.includes('.exe')) {
		}
	};

	console.log('initialDamages:', initialDamages);
	console.log('damageAssessmentResult:', damageAssessmentResult);

	return (
		<Dialog onClose={onClose} aria-labelledby="view-payment" open={open} maxWidth="lg" fullWidth>
			<DialogTitle id="view-payment" onClose={onClose}>
				View Quotation
			</DialogTitle>
			<DialogContent>
				<Box>
					<Grid container spacing={2} sx={{ pb: 4 }}>
						<Grid item xs={12}>
							<Typography sx={{ mt: 0, mx: 2, borderBottom: '1px #d3d3d3 solid' }} variant="h6" component="h6">
								Quotation Info
							</Typography>
							<List sx={{ display: 'flex', flexWrap: 'wrap' }}>
								{listingFunc('Quotation ID', data?.id)}
								{listingFunc('Quotation Number', data?.quotationNumber)}
								{listingFunc('Product Category', data?.yearlyCalculation[0]?.productCategory)}
								{listingFunc('Agent Name', agentProfile?.name)}
								{listingFunc('PIC Marketing', data?.picManager?.name)}
								{listingFunc('Insurance Company', data?.company?.companyName)}
								{listingFunc(
									'Vehicle Coverage',
									typeof data?.vehicleCoverage == 'object' && data?.vehicleCoverage.length
										? data?.vehicleCoverage.join(', ')
										: data?.vehicleCoverage
								)}
								{listingFunc('Scheme', agentProfile?.multilineScheme)}
								{listingFunc('Start Date', moment(data?.startDate).format('yyyy-MM-DD'))}
								{listingFunc('End Date', moment(data?.endDate).format('yyyy-MM-DD'))}
								{listingFunc('Policy Needs to be Printed?', data?.isPolicyPrintRequired ? 'Yes' : 'No')}
								{listingFunc('Delivery Address', data?.deliveryAdress || '-')}
								{listingFunc(
									'Submission Date & Time',
									moment(data?.submittedAt, 'DD/MM/YYYY HH:mm:ss').format('yyyy-MM-DD, hh:mm A')
								)}
							</List>
						</Grid>
						{(data?.isTimelyBased || data?.isMileageBased || data?.isSeniorCitizen) && (
							<Grid item xs={12}>
								<Typography sx={{ mt: 0, mx: 2, borderBottom: '1px #d3d3d3 solid' }} variant="h6" component="h6">
									Special Products
								</Typography>
								<List sx={{ display: 'flex', flexWrap: 'wrap' }}>
									{data?.isSeniorCitizen && (
										<>
											{data.documentFiles?.map((file) => {
												return (
													<>
														{file.name == 'Identity Document' && (
															<img src={file.url} width="280px" onError={imageErrorHandle} />
														)}
													</>
												);
											})}
											{listingFunc('Age', data?.customerAge || 'N/A')}
										</>
									)}
									{data?.isMileageBased && (
										<>
											{data.documentFiles?.map((file) => {
												return (
													<>
														{file.name == 'Odometer Image' && (
															<img src={file.url} width="280px" onError={imageErrorHandle} />
														)}
													</>
												);
											})}
											{listingFunc('Odometer Reading', data?.odometerReading || 'N/A')}
											{listingFunc('Vehicle Manufacture Year', data?.vehicleManufacterYear || 'N/A')}
											{listingFunc('Annual Mileage', data?.vehicleManufacterYear || 'N/A')}
										</>
									)}
									{data?.isTimelyBased && <>{listingFunc(' Number of Days', data?.timelyBasedDuration || 'N/A')}</>}
								</List>
							</Grid>
						)}

						<Grid item xs={12}>
							<Typography sx={{ mt: 0, mx: 2, borderBottom: '1px #d3d3d3 solid' }} variant="h6" component="h6">
								Payment
							</Typography>
							<List sx={{ display: 'flex', flexWrap: 'wrap' }}>
								{listingFunc('Quotation Status', paymentStatusFormat(data?.paymentStatus))}
								{listingFunc('Payment Amount', toLocaleString(data?.paymentAmount))}
								{listingFunc('Total Sum Assured Value', toLocaleString(data?.totalSumAssuredValue))}
								{premiumData && listingFunc('Total Premium', toLocaleString(premiumData?.totalPremium))}
								{listingFunc('Payment Status', paymentStatusFormat(data?.paymentProgressStatus))}
								{premiumData && listingFunc('Main Coverage Amount', toLocaleString(premiumData?.mainCoverage))}
								{premiumData && listingFunc('Additional Coverages Amount', toLocaleString(premiumData?.totalRiders))}
								{premiumData && listingFunc('Discount Percentage', toLocaleString(premiumData?.discountPercentage))}
								{premiumData && listingFunc('Discount Amount', toLocaleString(premiumData?.discount))}
								{premiumData && listingFunc('Cashback Percentage', toLocaleString(premiumData?.cashbackPercentage))}
								{premiumData && listingFunc('Cashback Amount', toLocaleString(premiumData?.cashback))}
								{listingFunc('Admin Fee', toLocaleString(data?.administrationCost))}
								{listingFunc('Stamp Duty', toLocaleString(data?.stampDuty))}
								{commissionData &&
									listingFunc('Sahabat Commission Percentage', `${(commissionPercentage || 0).toFixed(2)}%`)}
								{commissionData &&
									listingFunc('Sahabat Commission Amount', toLocaleString(commissionData?.totalCommisionAmount))}
								{pointsData && listingFunc('Sahabat Points Percentage', `${pointPercentage.toFixed(2) || 0}%`)}
								{pointsData && listingFunc('Sahabat Points Amount', toLocaleString(pointsData?.bangjaminPoints))}
								{commissionData &&
									listingFunc('Sahabat Commission Tax Percentage', `${commissionData?.taxPercentage || 0}%`)}
								{commissionData &&
									listingFunc('Sahabat Commission Tax Amount', toLocaleString(commissionData?.taxAmount))}
								{pointsData && listingFunc('Sahabat Points Tax Percentage', `${data?.taxPercentage || 0}%`)}
								{pointsData && listingFunc('Sahabat Points Tax Amount', toLocaleString(pointsData?.taxPoints))}
							</List>
						</Grid>

						{data?.quotationCompletetionSteps &&
							data?.quotationCompletetionSteps.length &&
							data?.quotationCompletetionSteps[6].isCompleted && (
								<Grid item xs={12}>
									<Typography sx={{ mt: 0, mx: 2, borderBottom: '1px #d3d3d3 solid' }} variant="h6" component="h6">
										Customer Info
									</Typography>
									<List sx={{ display: 'flex', flexWrap: 'wrap' }}>
										{listingFunc('Is Corporate?', data?.isCorporate == 'true' ? 'Yes' : 'No')}
										{listingFunc('Customer Name', data?.customerName)}
										{listingFunc('Mobile Number', data?.mobileNumber || 'N/A')}
										{listingFunc('Email Address', data?.email || 'N/A')}
										{listingFunc('KTP/NPWP Address', data?.permanentAddress || 'N/A')}
									</List>
								</Grid>
							)}

						{data?.quotationCompletetionSteps &&
							data?.quotationCompletetionSteps.length &&
							data?.quotationCompletetionSteps[3].isCompleted && (
								<Grid item xs={12}>
									<Typography sx={{ mt: 0, mx: 2, borderBottom: '1px #d3d3d3 solid' }} variant="h6" component="h6">
										Vehicle Info
									</Typography>
									<List sx={{ display: 'flex', flexWrap: 'wrap' }}>
										{data?.plateCode && data?.plateNumber && (
											<>{listingFunc('Plate Number', data?.plateCode + ' ' + data?.plateNumber)}</>
										)}
										{listingFunc('Vehicle Name', data?.vehicleName)}
										{data?.carDetail && (
											<>
												{listingFunc('Chassis Number', data?.carDetail?.chassisNumber)}
												{listingFunc('Machine Number', data?.carDetail?.engineNumber)}
												{listingFunc('Color', data?.carDetail?.color)}
											</>
										)}

										{listingFunc('Vehicle Condition', data?.vehicleCondition)}
										{listingFunc('Vehicle Manufacter Year', data?.vehicleManufacterYear)}
										{listingFunc('Vehicle Category', data?.vehicleCategory)}
										{listingFunc('Vehicle Value', toLocaleString(data?.vehiclePrice))}
										{listingFunc('Additional Accessories Value', toLocaleString(data?.additionalModificationValue))}
										{listingFunc(
											'Additional Accessories Details',
											data?.additionalAccessoriesDetails && data?.additionalAccessoriesDetails.length
												? data?.additionalAccessoriesDetails[0]
												: '-'
										)}
									</List>
								</Grid>
							)}

						{data?.quotationFiles && (
							<Grid item xs={12} sx={{ mx: 2 }}>
								<Typography sx={{ mt: 0, borderBottom: '1px #d3d3d3 solid' }} variant="h6" component="h6">
									Documents
								</Typography>
								<Table sx={{ minWidth: 650 }} aria-label="simple table">
									<TableHead>
										<TableRow>
											<TableCell>Name</TableCell>
											<TableCell>File</TableCell>
											<TableCell>Action</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										<TableRow key={4} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
											<TableCell sx={{ py: 1 }}>{`Registration Certificate`}</TableCell>
											<TableCell sx={{ py: 1 }}>
												<img
													src={data?.quotationFiles?.registrationCertificate}
													width="80px"
													onError={imageErrorHandle}
												/>
											</TableCell>
											<TableCell sx={{ py: 1 }}>
												<a target="_blank" href={data?.quotationFiles?.registrationCertificate}>
													Download
												</a>
											</TableCell>
										</TableRow>

										<TableRow key={4} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
											<TableCell sx={{ py: 1 }}>{`Identity Document`}</TableCell>
											<TableCell sx={{ py: 1 }}>
												<img src={data?.quotationFiles?.identityDocument} width="80px" onError={imageErrorHandle} />
											</TableCell>
											<TableCell sx={{ py: 1 }}>
												<a target="_blank" href={data?.quotationFiles?.identityDocument}>
													Download
												</a>
											</TableCell>
										</TableRow>
										{/* Front */}
										<TableRow key={1} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
											<TableCell sx={{ py: 1 }}>{`Front Vehicle`}</TableCell>
											<TableCell sx={{ py: 1 }}>
												<img src={data?.quotationFiles?.vehicleFrontImage} width="80px" onError={imageErrorHandle} />
											</TableCell>
											<TableCell sx={{ py: 1 }}>
												<a target="_blank" href={data?.quotationFiles?.vehicleFrontImage}>
													Download
												</a>
											</TableCell>
										</TableRow>

										{/* Back */}
										<TableRow key={2} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
											<TableCell sx={{ py: 1 }}>{`Back Vehicle`}</TableCell>
											<TableCell sx={{ py: 1 }}>
												<img src={data?.quotationFiles?.vehicleBackImage} width="80px" onError={imageErrorHandle} />
											</TableCell>
											<TableCell sx={{ py: 1 }}>
												<a target="_blank" href={data?.quotationFiles?.vehicleBackImage}>
													Download
												</a>
											</TableCell>
										</TableRow>

										{/* Left */}
										<TableRow key={3} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
											<TableCell sx={{ py: 1 }}>{`Left Vehicle`}</TableCell>
											<TableCell sx={{ py: 1 }}>
												<img src={data?.quotationFiles?.vehicleLeftImage} width="80px" onError={imageErrorHandle} />
											</TableCell>
											<TableCell sx={{ py: 1 }}>
												<a target="_blank" href={data?.quotationFiles?.vehicleLeftImage}>
													Download
												</a>
											</TableCell>
										</TableRow>

										{/* Left */}
										<TableRow key={4} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
											<TableCell sx={{ py: 1 }}>{`Right Vehicle`}</TableCell>
											<TableCell sx={{ py: 1 }}>
												<img src={data?.quotationFiles?.vehicleRightImage} width="80px" onError={imageErrorHandle} />
											</TableCell>
											<TableCell sx={{ py: 1 }}>
												<a target="_blank" href={data?.quotationFiles?.vehicleRightImage}>
													Download
												</a>
											</TableCell>
										</TableRow>

										<TableRow key={4} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
											<TableCell sx={{ py: 1 }}>{`Dashboard`}</TableCell>
											<TableCell sx={{ py: 1 }}>
												<img src={data?.quotationFiles?.dashboardImage} width="80px" onError={imageErrorHandle} />
											</TableCell>
											<TableCell sx={{ py: 1 }}>
												<a target="_blank" href={data?.quotationFiles?.dashboardImage}>
													Download
												</a>
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</Grid>
						)}

						{data?.vehicleFiles && (
							<Grid item xs={12} sx={{ mx: 2 }}>
								<Typography sx={{ mt: 0, borderBottom: '1px #d3d3d3 solid' }} variant="h6" component="h6">
									Vehicle Photos (Mobile)
								</Typography>
								<Table sx={{ minWidth: 650 }} aria-label="simple table">
									<TableHead>
										<TableRow>
											<TableCell>Name</TableCell>
											<TableCell>File</TableCell>
											<TableCell>Action</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{data.vehicleFiles?.map((file) => {
											return (
												<>
													<TableRow key={4} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
														<TableCell sx={{ py: 1 }}>{file.name}</TableCell>
														<TableCell sx={{ py: 1 }}>
															<img src={file.url} width="80px" onError={imageErrorHandle} />
														</TableCell>
														<TableCell sx={{ py: 1 }}>
															<a target="_blank" href={file.url}>
																Download
															</a>
														</TableCell>
													</TableRow>
												</>
											);
										})}
									</TableBody>
								</Table>
							</Grid>
						)}

						{data?.documentFiles && (
							<Grid item xs={12} sx={{ mx: 2 }}>
								<Typography sx={{ mt: 0, borderBottom: '1px #d3d3d3 solid' }} variant="h6" component="h6">
									Documents
								</Typography>
								<Table sx={{ minWidth: 650 }} aria-label="simple table">
									<TableHead>
										<TableRow>
											<TableCell>Name</TableCell>
											<TableCell>File</TableCell>
											<TableCell>Action</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{data.documentFiles?.map((file) => {
											return (
												<>
													{file.name != 'Additional Accessories Images' && (
														<TableRow key={4} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
															<TableCell sx={{ py: 1 }}>{file.name}</TableCell>
															<TableCell sx={{ py: 1 }}>
																<img src={file.url} width="80px" onError={imageErrorHandle} />
															</TableCell>
															<TableCell sx={{ py: 1 }}>
																<a target="_blank" href={file.url}>
																	Download
																</a>
															</TableCell>
														</TableRow>
													)}
													{file.name == 'Additional Accessories Images' &&
														file.url.map((accessoriesUrl, index) => {
															return (
																<TableRow key={4} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
																	<TableCell sx={{ py: 1 }}>{index == 0 ? file.name : ''}</TableCell>
																	<TableCell sx={{ py: 1 }}>
																		<img src={accessoriesUrl} width="80px" onError={imageErrorHandle} />
																	</TableCell>
																	<TableCell sx={{ py: 1 }}>
																		<a target="_blank" href={file.url}>
																			Download
																		</a>
																	</TableCell>
																</TableRow>
															);
														})}
												</>
											);
										})}
									</TableBody>
								</Table>
							</Grid>
						)}

						{data?.accessoriesFiles && (
							<Grid item xs={12} sx={{ mx: 2 }}>
								<Typography sx={{ mt: 0, borderBottom: '1px #d3d3d3 solid' }} variant="h6" component="h6">
									Additional Accessories
								</Typography>
								<Table sx={{ minWidth: 650 }} aria-label="simple table">
									<TableHead>
										<TableRow>
											<TableCell>Name</TableCell>
											<TableCell>File</TableCell>
											<TableCell>Price</TableCell>
											<TableCell>Action</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{data.accessoriesFiles?.map((file) => {
											return (
												<TableRow key={4} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
													<TableCell sx={{ py: 1 }}>{file.name}</TableCell>
													<TableCell sx={{ py: 1 }}>
														<img src={file.url} width="80px" onError={imageErrorHandle} />
													</TableCell>
													<TableCell sx={{ py: 1 }}>{toLocaleString(file.value)}</TableCell>
													<TableCell sx={{ py: 1 }}>
														{file.url && (
															<a target="_blank" href={file.url}>
																Download
															</a>
														)}
													</TableCell>
												</TableRow>
											);
										})}
									</TableBody>
								</Table>
							</Grid>
						)}

						{data?.customRiders && (
							<Grid item xs={12} sx={{ mx: 2 }}>
								<Typography sx={{ mt: 0, borderBottom: '1px #d3d3d3 solid' }} variant="h6" component="h6">
									Additional Coverages
								</Typography>
								<Table sx={{ minWidth: 650 }} aria-label="simple table">
									<TableHead>
										<TableRow>
											<TableCell>Name</TableCell>
											<TableCell>Code</TableCell>
											<TableCell>Value</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{data?.customRiders.map((rider, index) => (
											<TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
												<TableCell sx={{ py: 1 }}>
													{rider?.code == 'PAP'
														? `${rider?.displayName} (num of seats: ${rider?.default.seat})`
														: rider?.displayName}
												</TableCell>
												<TableCell sx={{ py: 1 }}>{rider?.code}</TableCell>
												<TableCell sx={{ py: 1 }}>
													{toLocaleString(rider?.value)}
													{rider?.default.value && <i> (Limit: {toLocaleString(rider?.default.value)})</i>}
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</Grid>
						)}

						{damageAssessmentResult && (
							<Grid item xs={12} sx={{ mx: 2 }}>
								<Typography sx={{ mt: 0, borderBottom: '1px #d3d3d3 solid' }} variant="h6" component="h6">
									Damage Assessment Result
								</Typography>
								<List sx={{ display: 'flex', flexWrap: 'wrap' }}>
									{listingFunc('Status', damageAssessmentResult?.status)}
									{listingFuncUrl('PDF Document', damageAssessmentResult?.pdfDocumentUrl)}
								</List>
								{damageAssessmentResult?.recognitionResult?.images && (
									<>
										<Typography sx={{ mt: 0, borderBottom: '1px #d3d3d3 solid' }} variant="h6" component="h6">
											Vehicle Photos (DA)
										</Typography>
										<Table sx={{ minWidth: 650 }} aria-label="simple table">
											<TableHead>
												<TableRow>
													<TableCell>Name</TableCell>
													<TableCell>Image</TableCell>
													<TableCell>Action</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{damageAssessmentResult.recognitionResult?.images.map((image) => {
													return (
														<>
															<TableRow key={4} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
																<TableCell sx={{ py: 1 }}>{image?.name}</TableCell>
																<TableCell sx={{ py: 1 }}>
																	<img src={image?.imageThumbnailUrl} width="80px" onError={imageErrorHandle} />
																</TableCell>
																<TableCell sx={{ py: 1 }}>
																	<a target="_blank" href={image?.imageUrl}>
																		Download
																	</a>
																</TableCell>
															</TableRow>
														</>
													);
												})}
											</TableBody>
										</Table>
									</>
								)}
								{damageAssessmentResult?.recognitionResult?.vehicle?.parts && (
									<>
										<Typography sx={{ mt: 0, borderBottom: '1px #d3d3d3 solid' }} variant="h6" component="h6">
											Recognized Damages
										</Typography>
										<Table sx={{ minWidth: 650 }} aria-label="simple table">
											<TableHead>
												<TableRow>
													<TableCell>Part</TableCell>
													<TableCell>Damage</TableCell>
													<TableCell>Image</TableCell>
													<TableCell>Action</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{damageAssessmentResult.recognitionResult?.vehicle?.parts.map((part) => {
													return (
														<>
															<TableRow key={4} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
																<TableCell sx={{ py: 1 }}>{part?.partName}</TableCell>
																<TableCell sx={{ py: 1 }}>{part?.damage?.name}</TableCell>
																<TableCell sx={{ py: 1 }}>
																	<img
																		src={part?.damage?.image?.imageThumbnailUrl}
																		width="80px"
																		onError={imageErrorHandle}
																	/>
																</TableCell>
																<TableCell sx={{ py: 1 }}>
																	<a target="_blank" href={part?.damage?.image?.imageUrl}>
																		Download
																	</a>
																</TableCell>
															</TableRow>
														</>
													);
												})}
											</TableBody>
										</Table>
									</>
								)}
								{damageAssessmentResult?.attachmentFiles && (
									<>
										<Typography sx={{ mt: 0, borderBottom: '1px #d3d3d3 solid' }} variant="h6" component="h6">
											Damage Assessment Attachments
										</Typography>
										<Table sx={{ minWidth: 650 }} aria-label="simple table">
											<TableHead>
												<TableRow>
													<TableCell>Name</TableCell>
													<TableCell>File</TableCell>
													<TableCell>Action</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{damageAssessmentResult.attachmentFiles?.map((file) => {
													return (
														<>
															<TableRow key={4} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
																<TableCell sx={{ py: 1 }}>{file.fileName}</TableCell>
																<TableCell sx={{ py: 1 }}>
																	{file.fileName.slice(-3) == 'pdf' ? (
																		<object data={file.fileDocUrl} width="80px" type="application/pdf" />
																	) : (
																		<img src={file.fileDocUrl} width="80px" onError={imageErrorHandle} />
																	)}
																</TableCell>
																<TableCell sx={{ py: 1 }}>
																	<a target="_blank" href={file.fileDocUrl}>
																		Download
																	</a>
																</TableCell>
															</TableRow>
														</>
													);
												})}
											</TableBody>
										</Table>
									</>
								)}
							</Grid>
						)}

						{
							<Grid item xs={12} sx={{ mx: 2 }}>
								<Typography sx={{ mt: 0, borderBottom: '1px #d3d3d3 solid' }} variant="h6" component="h6">
									<Grid container direction="row" justifyContent="space-between">
										<>Initial Damages</>
										{data?.paymentStatus == 'WAITING_APPROVAL' && canEdit && (
											<IconButton color="primary" size="small" onClick={() => handleAddInitialDamages()}>
												<AddIcon />
											</IconButton>
										)}
									</Grid>
								</Typography>
								{initialDamages && !initialDamages.length && (
									<>
										<Typography variant="subtitle1" componet="subtitle1" style={{ textAlign: 'center' }}>
											No Initial Damages
										</Typography>
									</>
								)}
								{initialDamages && initialDamages.length && (
									<Table sx={{ minWidth: 650 }} aria-label="simple table">
										<TableHead>
											<TableRow>
												<TableCell>Initial Damage</TableCell>
												{data?.paymentStatus == 'WAITING_APPROVAL' && <TableCell>Action</TableCell>}
											</TableRow>
										</TableHead>
										<TableBody>
											{initialDamages &&
												initialDamages.length &&
												initialDamages.map((initialDamage, index) => {
													return (
														<TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
															<TableCell sx={{ py: 1 }}>
																{initialDamage.isEditMode ? (
																	<TextField
																		fullWidth
																		margin="none"
																		variant="standard"
																		value={initialDamage.content}
																		onChange={(e) => handleChangeInitialDamagesInput(index, e)}
																	/>
																) : (
																	<>{initialDamage.content}</>
																)}
															</TableCell>
															{data?.paymentStatus == 'WAITING_APPROVAL' && initialDamage.isEditMode && (
																<TableCell sx={{ py: 1 }}>
																	<Button
																		disabled={initialDamage.isSubmitting}
																		onClick={() => handleSaveInitialDamages(index, initialDamage)}
																	>
																		{!initialDamage.isSubmitting ? 'Save' : 'Saving...'}
																	</Button>
																	<Button
																		color="warning"
																		onClick={() => handleCancelInitialDamages(index, initialDamage)}
																	>
																		Cancel
																	</Button>
																</TableCell>
															)}
															{data?.paymentStatus == 'WAITING_APPROVAL' && !initialDamage.isEditMode && (
																<TableCell sx={{ py: 1 }}>
																	<Button onClick={() => handleEditInitialDamages(initialDamage)}>Edit</Button>
																	<Button
																		disabled={initialDamage.isSubmitting}
																		color="warning"
																		onClick={() => handleDeleteInitialDamages(index, initialDamage)}
																	>
																		{!initialDamage.isSubmitting ? 'Delete' : 'Deleting...'}
																	</Button>
																</TableCell>
															)}
														</TableRow>
													);
												})}
										</TableBody>
									</Table>
								)}
							</Grid>
						}
					</Grid>
				</Box>
			</DialogContent>
		</Dialog>
	);
};

export default QuotationView;
